import Vue from 'vue'
import Vuex from 'vuex'
import breakpoints from "@/store/modules/breakpoints";
import goBack from "@/store/modules/goBack";
import menu from "@/store/modules/menu";
import overlay from "@/store/modules/overlay";
import modals from "@/store/modules/modals";
import darkmode from "@/store/modules/darkmode";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mobileMenuLinks: [{
        iconName: "ic_home",
        label: "Вход",
        href: "/",
        autorised: false
      },
      {
        iconName: "ic_person",
        label: "Клиенты",
        href: "/projects",
        autorised: true
      },
      {
        iconName: "ic_list",
        label: "Услуги",
        href: "/services",
        autorised: true
      },

      {
        iconName: "ic_email",
        label: "Рассылка",
        href: "/mailing",
        autorised: true
      },
      {
        iconName: "ic_about",
        label: "Посты",
        href: "/posts",
        autorised: true
      },
      {
        iconName: "ic_gear",
        label: "Настройки",
        href: "/setting",
        autorised: true
      },
      {
        iconName: "ic_logout",
        label: "Выйти",
        href: "/logout",
        autorised: true
      },
    ],
  },
  getters: {
    mobileMenuLinks: state => {
      return state.mobileMenuLinks;
    },
  },
  mutations: {},
  actions: {},
  modules: {
    breakpoints,
    goBack,
    menu,
    overlay,
    modals,
    darkmode
  }
})