var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.componentName,{tag:"component",class:{
     'section-h1': _vm.level === 1,
     'section-h2': _vm.level === 2,
     'section-h3': _vm.level === 3,
     'section-h4': _vm.level === 4,
     'section-h5': _vm.level === 5,
     'section-h6': _vm.level === 6,
     },domProps:{"innerHTML":_vm._s(_vm.text)}})
}
var staticRenderFns = []

export { render, staticRenderFns }