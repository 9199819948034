import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from "@/views/404";

Vue.use(VueRouter)

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual'
}

const routes = [{
    path: '/',
    name: 'home',
    component: () => import( /* webpackChunkName: "home" */ '../views/home')
  },
  {
    path: `/projects`,
    name: 'projects',
    component: () => import( /* webpackChunkName: "projects" */ '../views/projects')
  },
  {
    path: `/projects/:code`,
    name: 'projects/page',
    component: () => import( /* webpackChunkName: "projects-page" */ '../views/projects/page'),
    props: true
  },
  {
    path: `/services`,
    name: 'services',
    component: () => import( /* webpackChunkName: "services" */ '../views/services')
  },
  {
    path: `/services/:code`,
    name: 'settingDetails',
    component: () => import( /* webpackChunkName: "services" */ '../views/services/details'),
    props: true
  },
  {
    path: `/service/create`,
    name: 'createServices',
    component: () => import( /* webpackChunkName: "services" */ '../views/services/create'),
  },
  {
    path: `/setting`,
    name: 'setting',
    component: () => import( /* webpackChunkName: "services" */ '../views/setting')
  },
  {
    path: `/mailing`,
    name: 'mailing',
    component: () => import( /* webpackChunkName: "services" */ '../views/mailing')
  },
  {
    path: `/about`,
    name: 'about',
    component: () => import( /* webpackChunkName: "services" */ '../views/about')
  },
  {
    path: `/posts`,
    name: 'postsList',
    component: () => import( /* webpackChunkName: "services" */ '../views/posts')
  },
  {
    path: `/post/:code`,
    name: 'postDetails',
    component: () => import( /* webpackChunkName: "services" */ '../views/posts/details'),
    props: true
  },
  {
    path: `/messenger/:code`,
    name: 'messengerPage',
    component: () => import( /* webpackChunkName: "projects-page" */ '../views/messenger'),
    props: true
  },
  {
    path: `/logout`,
    name: 'logout',
    component: () => import( /* webpackChunkName: "services" */ '../views/logout')
  },

  {
    path: "*",
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router