<template functional>
  <div class="preloader__spinner--btn" />
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'spinner'
}
</script>

<style src="./style.scss" lang="scss" />
