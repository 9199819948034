var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.componentName,_vm._b({tag:"component",staticClass:"btn",class:{
      'btn--elevated': _vm.elevated,
      'btn--tonal': _vm.tonal,
      'btn--outline': _vm.outline,
      'btn--no-bg': _vm.notbg,
      'btn--spinner-icon': _vm.spinner,
  },attrs:{"type":_vm.type,"disabled":_vm.disabled,"to":_vm.to,"href":_vm.href,"target":_vm.href ? '_blank' : undefined},on:{"click":function($event){return _vm.clickHandler($event)}}},'component',_vm.$attrs,false),[(_vm.icon)?_c('icon',{staticClass:"btn__icon",attrs:{"icon-name":_vm.icon}}):_vm._e(),(_vm.spinner)?_c('spinner',{staticClass:"btn__icon-block"}):_vm._e(),_c('span',{staticClass:"btn__text",class:{ 'btn__text--loading': _vm.spinner }},[_vm._t("default")],2),_vm._t("count")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }