import Vue from 'vue';

Vue.filter("formatDate", date => {
    var d = new Date(date);
    var now = new Date();
    var monthNames = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
        'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
    ];
    var month = monthNames[d.getMonth()];
    var year = d.getFullYear();
    var day = d.getDate();
    var hours = String("00" + d.getHours()).slice(-2);
    var minutes = String("00" + d.getMinutes()).slice(-2);

    if (now.toDateString() === d.toDateString()) {
        return 'сегодня в ' + hours + ':' + minutes;
    } else {
        return day + ' ' + month + ' ' + year + ' ' + hours + ':' + minutes;
    }
})